import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { pluck, map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';
import { $ } from 'protractor';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttorneyProfileService {

  constructor(private httpClient: HttpClient) { }

  saveEmployeeDetails(companyId, employeeId, payload) {
    return this.httpClient.post(`/company/${companyId}/employee/${employeeId}`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    ); 
  }


  getEmployeeDetails(companyId, employeeId){
    return this.httpClient.get(`/company/${companyId}/employee/${employeeId}`).pipe(
      pluck('data'), map((data: Array<any>) => {
        return data;
      })
    )}

    updateProfilePic(companyId,employeeId,payload) {
      return this.httpClient.post(`/company/${companyId}/employee/${employeeId}/profileImage`, payload).pipe(
        map((response) => response)
      );
    }

    getAttorneyDetails(companyId, employeeId){
      return this.httpClient.get(`/company/${companyId}/attorney/${employeeId}/attorneyInfo`).pipe(
        pluck('data'), map((data: Array<any>) => {
          return data;
        })
      )}

      saveAttorneyDetails(companyId, employeeId, payload) {
        return this.httpClient.post(`/company/${companyId}/attorney/${employeeId}/attorneyInfo`, payload).pipe(
          map((response: ImagilityBaseResponse) => response)
        ); 
      }


      getEmailCounts(userId:any):Observable<any>{    
        return this.httpClient.get(`/getEmailsUnReadCount?userId=${userId}`).pipe(
          map((response: ImagilityBaseResponse) => {
            return response;
          })
        );
    }



    getUnifiedEmail(companyId:any, userId:any, taskId:any, folder:any, body):Observable<any>{    
        return this.httpClient.post(`/getEmails?userId=${userId}&taskId=${taskId}&folder=${folder}`, body).pipe(
          map((response: ImagilityBaseResponse) => {
            return response;
          })
        );
    }

    getUnifiedInteractionEmail(taskId:any, body):Observable<any>{    
      return this.httpClient.post(`/getEmails?taskId=${taskId}`, body).pipe(
        map((response:ImagilityBaseResponse) => {
        

          return response;
        })
      );
    } 

    
  saveUnifiedEmail(payload:any, body, attachment):Observable<any>{
    var formData;
    formData = new FormData();
    if(attachment.length > 0){        
        for(var i=0; i<attachment.length; i++){             
          formData.append("files", attachment[i]);
         }        
    }

    formData.append("body", JSON.stringify(payload));
    formData.append("htmlContent", body ? body.toString() : ' ');
   
   
    return this.httpClient.post(`/sendEmails`, formData).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }


  deleteUnifiedEmail(payload:any, body:string):Observable<any>{

    var formData;
    formData = new FormData();

    formData.append("body", JSON.stringify(payload));
    formData.append("htmlContent", body ? body.toString() : ' ');

    return this.httpClient.post(`/sendEmails`, formData).pipe(
      map((response: ImagilityBaseResponse) => {
        return response;
      })
    );
  }
}
