import { HttpClient, HttpHeaders,HttpBackend } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/';

@Injectable({
  providedIn: 'root'
})
export class EmailClientService {
  emailDetailSubscription$ = new Subject();
  emailArrSubscription$ = new Subject();
  folderChangeSubject$ = new BehaviorSubject<boolean>(false);
  profileDetails: any;
  selectedEmailAccount: any;
  totalrecords: any;
  outlookMessageType:string;
  accountType:string='outlook';
  selectedEmailId: any;
  selectedReplyType:string;
  selectedUsername:string;
  preservedMessageTypeForDraft:string;
  preservedMsgTypeForDraft:string;
  isPaginationDisplay:boolean;
  isDeleteFromDetail:boolean= false;
  constructor(private handler: HttpBackend,
              private readonly httpClient:HttpClient,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              ) { 
                // this.httpClient = new HttpClient(handler);
              }

  getInProgress():any{
    return this.msalBroadcastService.inProgress$;
  }   
  deleteEmailPermanently(emailId:string):Observable<any>{
    const headers = new HttpHeaders();
     return this.httpClient.delete(`${GRAPH_ENDPOINT}me/messages/${emailId}`,{headers: this.authHeader(), observe: 'response'})
  }

  deleteEmail(emailId:string):Observable<any>{
    const headers = new HttpHeaders();
     return this.httpClient.post(`${GRAPH_ENDPOINT}me/messages/${emailId}/move`,{"destinationId": "deleteditems"},{headers: this.authHeader(), observe: 'response'})
  }               

  getProfile():Observable<any> {
    return this.httpClient.get(`${GRAPH_ENDPOINT}me`)
  }

  getMessages(select:string,skipItems:number,topItems:number, emailType: string, id: string):Observable<any> { 
    switch (emailType) {
      case 'Inbox':
          return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders/inbox/messages?$select=${select}&top=${topItems}&skip=${skipItems}&$count=true`);
      case 'Sent Items':
          return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders/sentitems/messages?$select=${select}&top=${topItems}&skip=${skipItems}&$count=true`);
      case 'Drafts':
          return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders/drafts/messages?$select=${select}&top=${topItems}&skip=${skipItems}&$count=true`);
      case 'Deleted Items':
          return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders/deleteditems/messages?$select=${select}&top=${topItems}&skip=${skipItems}&$count=true`);
      default: { 
        return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders/${id}/messages?$select=${select}&top=${topItems}&skip=${skipItems}&$count=true`);
      } 
    }
  }

  getMessageDetails(mailId:string):Observable<any> {
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/messages/${mailId}`)
  }
  
  getDraftMessages():Observable<any> { 
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders?top=100`)
  }
  
  getEmailAttachments(id:string):Observable<any> { 
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/messages/${id}/attachments`);
  }

  getSingleEmail(id:string,select:string):Observable<any> { 
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/messages/${id}?select=${select}`)
  }

  getPerticularFolderEmail(response:any, select:string,topItems:number,skipItems:number ):Observable<any> { 
    // let subjectId= sessionStorage.companyId && sessionStorage.userId ?"IM-"+sessionStorage.companyId+sessionStorage.userId :""
    let subjectId ='[3126IMAGILITY]';
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders/${response}/messages?$orderby=receivedDateTime desc&$select=${select}&$filter=receivedDateTime gt 2016-01-01T00:00:00Z and contains(subject,'${subjectId}')&top=${topItems}&skip=${skipItems}&$count=true`)
  }
  
  saveEmail(message:any):Observable<any>{
     return this.httpClient.post(`${GRAPH_ENDPOINT}me/messages`,message,{headers: this.authHeader(), observe: 'response'})
  }
  
  sendEmail(message:any):Observable<any>{
     return this.httpClient.post(`${GRAPH_ENDPOINT}me/sendMail`,message,{headers: this.authHeader(), observe: 'response'});
  }

  sendReplyAndForwardEmail(message:any,emailId:string,replyType:string):Observable<any>{
    if(this.preservedMessageTypeForDraft=='Drafts'){
      this.preservedMsgTypeForDraft=this.preservedMessageTypeForDraft;
      this.preservedMessageTypeForDraft=''    
      return this.httpClient.post(`${GRAPH_ENDPOINT}me/messages/${emailId}/send`,message,{headers: this.authHeader(), observe: 'response'})
    }
    else {
      return this.httpClient.post(`${GRAPH_ENDPOINT}me/messages/${emailId}/${replyType}`,message,{headers: this.authHeader(), observe: 'response'})
    }
  }
  
  private authHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  loginOutlook() {
    
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.getProfile()
            this.folderChangeSubject$.next(true)
         });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.getProfile();
            this.folderChangeSubject$.next(true);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logoutOutlook() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  updateMsgFlag(mailId:string,message:any){
     return this.httpClient.patch(`${GRAPH_ENDPOINT}me/messages/${mailId}`,message,{headers: this.authHeader(), observe: 'response'});
  }

  createFolder(message){
    return this.httpClient.post(`${GRAPH_ENDPOINT}me/mailFolders`,message,{headers: this.authHeader(), observe: 'response'})
  }

  moveEmails(emailId:string,destinationId:string):Observable<any>{
    const headers = new HttpHeaders();
     return this.httpClient.post(`${GRAPH_ENDPOINT}me/messages/${emailId}/move`,{"destinationId": destinationId},{headers: this.authHeader(), observe: 'response'})
  }   

  getFolders(){
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/mailFolders/?includeHiddenFolders=true&top=100`); //&skip=10
  }

  createEvents(message){
    return this.httpClient.post(`${GRAPH_ENDPOINT}me/events?sendUpdates=all`,message,{headers: this.authHeader(), observe: 'response'})
  }
  
  updateEvents(meetingId,message){
    return this.httpClient.patch(`${GRAPH_ENDPOINT}me/events/${meetingId}?sendUpdates=all`,message,{headers: this.authHeader(), observe: 'response'})
  }
  
  cancelEvents(meetingId,message){
    return this.httpClient.post(`${GRAPH_ENDPOINT}me/events/${meetingId}/cancel`,message,{headers: this.authHeader(), observe: 'response'})
  }

  getMeetingDetails(id:string){
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/events/${id}`);
  }

  addOutlookAttachment(id: any, attachments: any){
    return this.httpClient.post(`${GRAPH_ENDPOINT}me/events/${id}/attachments`,attachments,{headers: this.authHeader(), observe: 'response'})
  }

  getOutlookAttachment(id:string):Observable<any> { 
    return this.httpClient.get(`${GRAPH_ENDPOINT}me/events/${id}/attachments`);
  }


 

}