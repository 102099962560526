<div class="position-absolute" style="right:3rem">
  <app-help-video-overlay #helpVideoComponent></app-help-video-overlay>
</div>
<div class="row mt-4 mb-2 pt-4">
  <div class="col">
    <div class="col">
      <div class="row">
        <div class="ml-auto pb-3">
          <div class="mr-3 float-left">
            <div class="float-left mr-2 legend completed"></div>
            <div class="float-left"><span>Completed</span></div>
          </div>
          <div class="mr-3 float-left">
            <div class="float-left mr-2 legend submitted"></div>
            <div class="float-left"><span>Submitted</span></div>
          </div>
          <div class="mr-3 float-left">
            <div class="float-left mr-2 legend inprogress"></div>
            <div class="float-left"><span>In-Progress</span></div>
          </div>
          <div class="float-left mr-3">
            <div class="float-left mr-2 legend new"></div>
            <div class="float-left"><span>New</span></div>
          </div>
          <div class="float-left d-flex align-items-center">
            <div class="float-left mr-2 legend over-due"></div>
            <div class="float-left"><span>Over Due</span></div>
          </div>
        </div>
      </div>
      <div class="row timeline-row" [hidden]="selectedTaskType == 'RFE'">
        <ng-container *ngIf="!isBeneficiaryIntiate">
          <div class="col justify-content-center col-styled col-bene">
            <span class="time-title">
              Beneficiary Actions
            </span>
          </div>
          <div class="col justify-content-center col-styled col-att">
            <span class="time-title">
              Petitioner / Lawfirm Actions
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="isBeneficiaryIntiate">
          <div class="col justify-content-center col-styled col-bene">
            <span class="time-title">
              Timeline Steps
            </span>
          </div>
        </ng-container>
      </div>
      <div class="row timeline-row"
        *ngIf="selectedTaskType == 'RFE' && !rfeDialogDisplay || rfeDialogDisplay && this.userType !='Attorney'">
        <div class="col justify-content-center"
          [ngClass]="{'highlight':this.userType =='Beneficiary' || this.userType =='Petitioner'}">
          <span class="time-title">
            Petitioner / Beneficiary Actions
          </span>
        </div>
        <div class="col justify-content-center" [ngClass]="{'highlight':this.userType =='Attorney'}">
          <span class="time-title">
            Lawfirm Actions
          </span>
        </div>
      </div>
      <div class="row timeline-row timeline-row-header-left"
        *ngIf="selectedTaskType == 'RFE' && rfeDialogDisplay && this.userType =='Attorney'">
        <div class="col col-6 justify-content-center highlight">
          <span class="time-title">
            Lawfirm Actions
          </span>
        </div>
      </div>
      <div class="row col-md-12 container px-0">
        <div class="col px-0">
          <div [ngClass]="isBeneficiaryIntiate ? 'beneficiaryInitiate' : ''" [hidden]="selectedTaskType == 'RFE'">
            <div class="petition-timeline">
              <div *ngFor="let item of taskObj; let i=index;">
                <div *ngIf="item.isVisible" [class.complete-icon]="item?.stepStatusDetails?.stepStatusCode=='COMPLETE'"
                  [class.submit-icon]="item?.stepStatusDetails?.stepStatusCode=='SUBMIT'"
                  [class.inprogress-icon]="item?.stepStatusDetails?.stepStatusCode=='INPROGRESS'"
                  [class.new-icon]="item?.stepStatusDetails?.stepStatusCode=='NEW'"
                  [class.timeline-left]="item.isBeneficiaryAction==1"
                  [class.timeline]="item.isPetitionerAction==1 || item.isAttorneyAction==1"
                  [ngClass]="item.stepName === 'Wait for USCIS Decision' ? 'wait ' + (!item.isEditable ? '' : item['USCISResponseClass']) : ''">
                  <!-- <div *ngIf="(!item.stepStatusDetails || item?.stepStatusDetails?.stepStatusCode==='NEW') && i === 0"
                    class="start-btn">
                    <span class="start-span">
                      <span class="material-icons"> keyboard_arrow_down </span>Start Here
                    </span>
                  </div> -->
                  <div [class.complete]="item?.stepStatusDetails?.stepStatusCode=='COMPLETE'"
                    [class.abandon]="item?.stepStatusDetails?.stepStatusCode=='CANCEL' || item?.stepStatusDetails?.stepStatusCode=='ABANDON'"
                    [class.inprogress]="item?.stepStatusDetails?.stepStatusCode=='INPROGRESS'"
                    [class.new]="item?.stepStatusDetails?.stepStatusCode=='NEW'"
                    [class.submit]="item?.stepStatusDetails?.stepStatusCode=='SUBMIT'">
                    <a class="timeline-content d-inline-block" mat-button [ngClass]="{'not-editable': !item.isEditable}"
                      (click)="item.isEditable && getStepStatusUpdate(item)">
                      {{item.stepName}}
                      <span class="pl-1 material-icons"
                        *ngIf="item?.stepStatusDetails?.stepStatusCode==='COMPLETE' && item?.isBeneficiaryNotify === 2 && userType !=='Beneficiary'">
                        notifications
                      </span>
                    </a>
                    <span *ngIf="item.stepName == 'Get Receipt'" class="hourglass">
                      <div class="icon-background">
                        <i class="material-icons text-primary">hourglass_empty</i>
                      </div>
                      <span *ngIf="receiptETADateRange">
                        <div class="d-inline-block align-bottom">
                          <small>
                            <div>Receipt ETA</div>
                            <div class="d-inline-block text-primary">{{receiptETADateRange}}</div>
                          </small>
                        </div>
                      </span>
                      <span *ngIf="!receiptETADateRange" class="text-secondary">
                        <small>Not Available</small>
                      </span>
                    </span>
                    <span *ngIf="item.stepName == 'Wait for USCIS Decision'">
                      <div class="icon-background">
                        <i class="material-icons text-primary">hourglass_empty</i>
                      </div>
                      <span *ngIf="decisionETADateRange">
                        <div class="d-inline-block align-bottom">
                          <small>
                            <div>Notice ETA</div>
                            <div class="d-inline-block text-primary">{{decisionETADateRange}}</div>
                          </small>
                        </div>
                      </span>
                      <span *ngIf="!decisionETADateRange" class="text-secondary">
                        <small>Not Available</small>
                      </span>
                    </span>
                    <!-- <span *ngIf="this.userType !='Beneficiary' && item.stepName == 'Select SOC Code & Assign LCA'"> -->
                    <!-- <span>
                      <div class="icon-background">
                        <i class="fa fa-bell text-primary"></i>
                      </div>
                      <span>
                        <div class="d-inline-block icn" (click)="notifyBeneForLcaAck(item)">
                          <small>
                            <div class="text-primary">Notify Beneficiary</div>
                          </small>
                        </div>
                      </span>
                    </span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="selectedTaskType != 'RFE'"
            *ngIf="!rfeDialogDisplay || rfeDialogDisplay && this.userType !='Attorney'" class="rfeTimeline">
            <p-timeline [value]="taskObj">

              <ng-template pTemplate="content" let-task let-i="index">
                <div
                  *ngIf="task.isPetitionerAction==1 && task.stepName != 'Provide Additional Details (Petitioner Action)' || task.isAttorneyAction==1 ">
                  <hr class="separator">

                  <div
                    *ngIf="(!task.stepStatusDetails || task?.stepStatusDetails?.stepStatusCode==='NEW') && taskObj.indexOf(task) === 0"
                    class="start-btn">
                    <span class="start-span">
                      <span class="material-icons"> keyboard_arrow_down </span>Start Here
                    </span>
                  </div>
                  <p-button
                    [ngClass]="{'complete':task?.stepStatusDetails?.stepStatusCode=='COMPLETE','inprogress': task?.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'new':task?.stepStatusDetails?.stepStatusCode === 'NEW' }"
                    (click)="getRFEStepStatusUpdate(task)">{{ task.stepName }}</p-button>

                  <span *ngIf="userType === 'Petitioner' || userType === 'Attorney'">
                    <div class="icon-background notes task-notes-btn" [pTooltip]="task?.notes?.noteText"
                      [class.yellow]="task?.notesAvailable===true" #btnNote (click)="notepanel.toggle($event, btnNote)">
                      <!-- <i class="material-icons icn-note">assignment</i> -->
                      <span class="icn-note"></span>
                    </div>
                    <p-overlayPanel #notepanel appendTo="btnNote" class="notepanel" [showCloseIcon]="true"
                      [dismissable]="true">
                      <ng-template pTemplate>
                        <task-notes-lib [step]="task" (noteAdded)="updateNoteAvailability($event, task)">
                        </task-notes-lib>
                        <!-- <app-task-notes-reworked [step]="step" (noteAdded)="updateNoteAvailability($event, task)"></app-task-notes-reworked> -->
                      </ng-template>
                    </p-overlayPanel>
                  </span>

                </div>
              </ng-template>
              <ng-template pTemplate="opposite" let-task>
                <div
                  *ngIf="task.isBeneficiaryAction==1 || task.stepName === 'Provide Additional Details (Petitioner Action)'">
                  <p-button (click)="getRFEStepStatusUpdate(task)"
                    [ngClass]="{'complete':task?.stepStatusDetails?.stepStatusCode=='COMPLETE','submitted':task?.stepStatusDetails?.stepStatusCode=='SUBMIT','inprogress': task?.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'new':task?.stepStatusDetails?.stepStatusCode === 'NEW' }">
                    {{ task.stepName }}</p-button>
                  <hr class="separator">
                </div>
              </ng-template>
              <ng-template pTemplate="marker" let-task>
                <span class="custom-marker">
                  <i
                    [ngClass]="{'complete':task?.stepStatusDetails?.stepStatusCode=='COMPLETE','inprogress': task?.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'new':task?.stepStatusDetails?.stepStatusCode === 'NEW', 'submitted':task?.stepStatusDetails?.stepStatusCode === 'SUBMIT' }"></i>
                </span>
              </ng-template>
            </p-timeline>
          </div>
          <div [hidden]="selectedTaskType != 'RFE'" *ngIf="rfeDialogDisplay && this.userType =='Attorney'"
            class="rfeTimeline rfeTimelineLeft">
            <p-timeline [value]="taskObj">
              <ng-template pTemplate="content" let-task>
                <div
                  *ngIf="task.isPetitionerAction==1 && task.stepName != 'Provide Additional Details (Petitioner Action)' || task.isAttorneyAction==1 ">
                  <hr class="separator">
                  <p-button class="custom-marker" (mouseenter)="mouseEnter(task?.completionStatus)"
                    [ngbTooltip]="task?.estimatedTime" [tooltipClass]="toolTipCustomClass" 
                    [ngClass]="{'complete':task?.stepStatusDetails?.stepStatusCode=='COMPLETE',
                    'submitted':task?.stepStatusDetails?.stepStatusCode=='SUBMIT',
                    'inprogress': task?.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 
                    'new':task?.stepStatusDetails?.stepStatusCode === 'NEW',
                    'overDue': task?.completionStatus === 'OVERDUE'}">
                    {{ task.stepName }}
                  </p-button>
                  <!-- Step Notes -->
                  <span>
                    <div class="icon-background notes task-notes-btn" [class.yellow]="task?.notesAvailable===true"
                      #btnNote (click)="notepanel.toggle($event, btnNote)">
                      <span class="icn-note"></span>
                    </div>
                    <p-overlayPanel #notepanel appendTo="btnNote" class="notepanel" [showCloseIcon]="true"
                      [dismissable]="true">
                      <ng-template pTemplate>
                        <task-notes-lib [step]="task" (noteAdded)="updateNoteAvailability($event, task)">
                        </task-notes-lib>
                        <!-- <app-task-notes-reworked [step]="step" (noteAdded)="updateNoteAvailability($event, step)"></app-task-notes-reworked> -->
                      </ng-template>
                    </p-overlayPanel>
                  </span>
                  <!-- End -->
                </div>

              </ng-template>
              <ng-template pTemplate="marker" let-task>
                <span class="custom-marker">
                  <i
                    [ngClass]="{'complete':task?.stepStatusDetails?.stepStatusCode=='COMPLETE','submitted':task?.stepStatusDetails?.stepStatusCode=='SUBMIT','inprogress': task?.stepStatusDetails?.stepStatusCode === 'INPROGRESS', 'new':task?.stepStatusDetails?.stepStatusCode === 'NEW' }"></i>
                </span>
              </ng-template>
            </p-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="selectedTaskType == 'RFE'">
  <app-rfe-dialog [rfeTimelineModalInput]="rfeTimelineModalInput" [rfeDialogDisplay]="rfeDialogDisplay"
    [stepName]="stepName" (notify)="parentListener($event)">/</app-rfe-dialog>
</div>

<p-dialog [(visible)]="showNotifyBeneAckContent" [style]="{width: '55vw'}" styleClass="timeline-bene-ack-dialog">
  <ng-template pTemplate="header" class="p-0 m-0">
    <div>
      <div class="col-12">
        <h5>Notify Beneficiary to update LCA Acknowledgement</h5>
      </div>
    </div>
  </ng-template>
  <div class="p-3">
    <p>A Notification will be send to the Beneficiary that tha LCA has been assigned to the petition and the Beneficiary
      needs to Acknowledge the LCA.</p>
  </div>
  <hr />
  <div>
    <div class="d-flex flex-row-reverse bd-highlight">
      <div class="p-2">
        <button pButton type="submit" class="p-button-primary" label="Notify Now" title="Notify Now"
          (click)="sendNotificationForBeneLcaAck()"></button>
      </div>
      <div class="p-2">
        <button pButton type="button" class="p-button-secondary" label="I will do it later" title="I will do it later"
          (click)="cancelNow()"></button>
      </div>
    </div>
  </div>
</p-dialog>