import { Injectable, InjectionToken } from '@angular/core';
// import {TaskDetailsComponent} from '../../../projects/task-details/src/projects';
import { EmailAddressComponent } from '../modules/common-component/email-address/email-address.component';
import { AddressDetailsComponent } from '../modules/common-component/address-details/address-details.component';
import { CommonQuestionsComponent } from 'common-quetions';
import { TaskDetailsComponent } from 'task-details';
import { CustomTextFieldComponent } from 'custom-text-field';
import { CustomRadioButtonComponent } from 'custom-radio-button';
import { CreateTaskActionsComponent } from 'create-task-actions';
import { CustomCheckboxComponent } from 'custom-checkbox';
import { SubTaskPetitionDetailsComponent } from 'sub-task-petition-details';
import { CustomDropdownComponent } from 'custom-dropdown';
import { CustomCalenderComponent } from 'custom-calender';
import { CustomChipComponent } from 'custom-chip';
import { CustomButtonComponent } from 'custom-button';
import { CommonEditorComponent } from 'common-editor';
//import { VdCustomFileUploadComponent } from 'vd-custom-file-upload';
import { SubTaskExtraOrdinaryAbilityComponent } from 'sub-task-extra-ordinary-ability';
import { CreateTaskLibraryComponent } from 'create-task-library';
import { JobDutiesComponent } from 'job-duties';
//import { VdSwitchGroupComponent } from 'projects/vd-switch-group/src/lib/vd-switch-group.component';
import { StepStatusUpdateComponentLibraryComponent } from 'step-status-update-component-library';
import { VdSwitchGroupComponent } from 'vd-switch-group';
import { VdDividerComponent } from 'vd-divider';
import { VdLabelComponent } from 'vd-label';
import { CustomQuestionsGroupComponent } from 'custom-questions-group';
import { VdTextAreaComponent } from 'vd-text-area';
import { CustomGridEditorLibraryComponent } from 'custom-grid-editor-library';
import { VdPhoneComponent } from 'vd-phone';
import { VdCustomFileUploadComponent } from 'vd-custom-file-upload';
import { VdSubTaskH4Component } from 'vd-sub-task-h4';
import { VdConfigPetitionSubTaskComponent } from 'vd-config-petition-sub-task';
import { VdH4TaskDetailsComponent } from 'vd-h4-task-details';
import { VdEadLibraryComponent } from 'vd-ead-library';
import { VdAddressComponent } from 'vd-address';
import { VdAddGenerateFormComponent } from 'vd-add-generate-form';
import { VdCountryStateComponent } from 'vd-country-state';
import { VdCountComponent } from 'vd-count';
import { CustomButtonActionComponent } from 'custom-button-action';
import { FdMasterTableListCardComponent } from 'fd-master-table-list-card';
import { CustomFormRadioButtonComponent } from 'custom-form-radio-button';
import { SubTaskInterpreterDetailsComponent } from 'sub-task-interpreter-details';
import { CustomDateTimePickerComponent } from 'custom-date-time-picker';
import { FbEmployeeDetailsComponent } from 'fb-employee-details';
import { FbNoticePeriodComponent } from 'fb-notice-period';
import { CustomReadonlyFormComponent } from 'custom-readonly-form';
import { FbCustomDatePickerGroupComponent } from 'fb-custom-date-picker-group';
import { LibResignationSummaryComponent } from 'lib-resignation-summary';
import { FbLeavePolicyComponent } from 'fb-leave-policy';
import { CustomNumberFieldComponent } from 'custom-number-field';
import { FbEmailContentComponent } from 'fb-email-content';
import { LibLeaveSummaryComponent } from "lib-leave-summary";
import { DailyAttendanceSummaryComponent } from "daily-attendance-summary";
import { MarkInOutComponent } from 'mark-in-out';
import { TimesheetSummaryComponent } from 'timesheet-summary';
import { CorrectionSummaryComponent } from 'correction-summary';
import { OvertimeSummaryComponent } from 'overtime-summary';
import { ShortbreakSummaryComponent } from 'shortbreak-summary';
import { LibProjectTypeComponent } from 'lib-project-type';
import { LibViewProjectComponent } from 'lib-view-project';
import { CreateProjectTimesheetComponent } from 'create-project-timesheet';
import { LibViewTimesheetComponent } from 'lib-view-timesheet';
import { FbCustomChipSelectionComponent } from 'fb-custom-chip-selection';
import { LibRolesResponsibilityComponent } from 'lib-roles-responsibility';
import { LibRequisitionDecisionStatusComponent } from 'lib-requisition-decision-status';
import { BasicPositionDetailsComponent } from 'basic-position-details';
import { LibCreateInvoiceComponent } from 'lib-create-invoice';
import { LibGenerateReceiptComponent } from 'lib-generate-receipt';
import { LibJobPostingDetailsComponent } from 'lib-job-posting-details';
import { LibPdfViewerComponent } from 'lib-pdf-viewer';
import { LibApplicantDetailsComponent } from 'lib-applicant-details';
import { CustomReadonlyLabelValueLibraryComponent } from 'custom-readonly-label-value-library';
import { CustomPeriodSelectionComponent } from 'custom-period-selection';
import { SelectCurrencyComponent } from 'select-currency';
import { LibViewPayslipComponent } from "lib-view-payslip";
import { TalentEditDetailsComponent } from "talent-edit-details";
import { BulkUploadDashboardComponent } from "bulk-upload-dashboard";
import { NewTalentUploadComponent } from "new-talent-upload";
import { ViewCandidateDetailsComponent } from "view-candidate-details";
import { LibPictureUploadComponent } from 'lib-picture-upload';
import { LibViewClientInfoComponent } from 'lib-view-client-info';
import { LibEvaluationDetailsComponent } from 'lib-evaluation-details';
import { LibTemplateBuilderComponent } from 'lib-template-builder';
import { LibTemplateViewerComponent } from 'lib-template-viewer';
import { LibAssignMultipleJobIdComponent } from 'lib-assign-multiple-job-id';
import { BasicPositionEditDetailsComponent } from 'basic-position-edit-details';
import { LibPreviewInvoiceComponent } from 'lib-preview-invoice';
import { SupplierEditDetailsComponent } from 'supplier-edit-details';
import { EfilingVersionStatusComponent } from 'efiling-version-status';
import { LibAddNewEmployeeComponent } from 'lib-add-new-employee';
@Injectable({ providedIn: 'root' })
export class ComponentLoaderService {

        constructor() {
        }

        public static getComponent = {
                'EmailAddressComponent': EmailAddressComponent,
                'AddressDetailsComponent': VdAddressComponent,
                'TaskDetailsComponent': TaskDetailsComponent,
                'SubTaskPetitionDetailsComponent': SubTaskPetitionDetailsComponent,
                'CommonQuestionsComponent': CommonQuestionsComponent,
                'CustomTextFieldComponent': CustomTextFieldComponent,
                'CustomRadioButtonComponent': CustomRadioButtonComponent,
                'CustomFormRadioButtonComponent': CustomFormRadioButtonComponent,
                'CreateTaskActionsComponent': CreateTaskActionsComponent,
                'CustomCheckboxComponent': CustomCheckboxComponent,
                'CustomDropdownComponent': CustomDropdownComponent,
                'CustomCalenderComponent': CustomCalenderComponent,
                'CustomDateTimePickerComponent': CustomDateTimePickerComponent,
                'CustomChipComponent': CustomChipComponent,
                'CustomButtonComponent': CustomButtonComponent,
                'VdCustomFileUploadComponent': VdCustomFileUploadComponent,
                'CommonEditorComponent': CommonEditorComponent,
                'CreateTaskLibraryComponent': CreateTaskLibraryComponent,
                'SubTaskExtraOrdinaryAbility': SubTaskExtraOrdinaryAbilityComponent,
                'JobDutiesComponent': JobDutiesComponent,
                'VdSwitchGroupComponent': VdSwitchGroupComponent,
                'StepStatusUpdateComponentLibraryComponent': StepStatusUpdateComponentLibraryComponent,
                'VdDividerComponent': VdDividerComponent,
                'VdLabelComponent': VdLabelComponent,
                'CustomQuestionsGroupComponent': CustomQuestionsGroupComponent,
                'CustomGridEditor': CustomGridEditorLibraryComponent,
                'VdTextAreaComponent': VdTextAreaComponent,
                'VdPhoneComponent': VdPhoneComponent,
                'VdSubTaskH4Component': VdSubTaskH4Component,
                'VdConfigPetitionSubTaskComponent': VdConfigPetitionSubTaskComponent,
                'VdH4TaskDetailsComponent': VdH4TaskDetailsComponent,
                'VdEadLibraryComponent': VdEadLibraryComponent,
                'VdAddGenerateFormComponent': VdAddGenerateFormComponent,
                'VdCountryStateComponent': VdCountryStateComponent,
                'VdCountComponent': VdCountComponent,
                'CustomButtonActionComponent': CustomButtonActionComponent,
                'FdMasterTableListCardComponent': FdMasterTableListCardComponent,
                'SubTaskInterpreterDetailsComponent': SubTaskInterpreterDetailsComponent,
                'FbEmployeeDetailsComponent': FbEmployeeDetailsComponent,
                'FbNoticePeriodComponent': FbNoticePeriodComponent,
                'CustomReadonlyFormComponent': CustomReadonlyFormComponent,
                'FbCustomDatePickerGroupComponent': FbCustomDatePickerGroupComponent,
                'ResignationSummary': LibResignationSummaryComponent,
                'FbLeavePolicyComponent': FbLeavePolicyComponent,
                'CustomNumberFieldComponent': CustomNumberFieldComponent,
                'FbEmailContentComponent': FbEmailContentComponent,
                'LeaveSummary': LibLeaveSummaryComponent,
                'DailyAttendanceSummary': DailyAttendanceSummaryComponent,
                'TimesheetSummary': TimesheetSummaryComponent,
                'MarkInOutComponent': MarkInOutComponent,
                'CorrectionSummary': CorrectionSummaryComponent,
                'OvertimeSummary': OvertimeSummaryComponent,
                'ShortbreakSummary': ShortbreakSummaryComponent,
                'ProjectType': LibProjectTypeComponent,
                'ViewProject': LibViewProjectComponent,
                'CreateProjectTimesheet': CreateProjectTimesheetComponent,
                'ViewProjectTimesheet': LibViewTimesheetComponent,
                'FbCustomChipSelectionComponent': FbCustomChipSelectionComponent,
                'RolesResponsibility': LibRolesResponsibilityComponent,
                'RequisitionDecisionStatus': LibRequisitionDecisionStatusComponent,
                'BasicPositionDetailsComponent': BasicPositionDetailsComponent,
                'CreateInvoice': LibCreateInvoiceComponent,
                'GenerateReceipt': LibGenerateReceiptComponent,
                'LibJobPostingDetailsComponent': LibJobPostingDetailsComponent,
                'PdfViewer': LibPdfViewerComponent,
                'LibApplicantDetailsComponent': LibApplicantDetailsComponent,
                'CustomReadonlyLabelValueLibraryComponent': CustomReadonlyLabelValueLibraryComponent,
                'CustomPeriodSelectionComponent': CustomPeriodSelectionComponent,
                'SelectCurrencyComponent': SelectCurrencyComponent,
                'ViewPayslipComponent': LibViewPayslipComponent,
                'TalentEditDetailsComponent': TalentEditDetailsComponent,
                'BulkUploadDashboardComponent': BulkUploadDashboardComponent,
                'NewTalentUploadComponent': NewTalentUploadComponent,
                'ViewCandidateDetailsComponent': ViewCandidateDetailsComponent,
                'LibPictureUploadComponent': LibPictureUploadComponent,
                'LibViewClientInfoComponent': LibViewClientInfoComponent,
                'LibEvaluationDetailsComponent': LibEvaluationDetailsComponent,
                'LibTemplateBuilderComponent': LibTemplateBuilderComponent,
                'LibTemplateViewer': LibTemplateViewerComponent,
                'LibAssignMultipleJobIdComponent': LibAssignMultipleJobIdComponent,
                'BasicPositionEditDetailsComponent': BasicPositionEditDetailsComponent,
                'LibPreviewInvoice': LibPreviewInvoiceComponent,
                'SupplierEditDetailsComponent': SupplierEditDetailsComponent,
                'EfilingVersionStatusComponent':EfilingVersionStatusComponent,
                'LibAddNewEmployeeComponent': LibAddNewEmployeeComponent
        }

}
