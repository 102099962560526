import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImagilityBaseResponse } from 'app-models';
import { map } from 'rxjs/internal/operators/map';
import { EncryptService } from 'src/app/modules/core/http/http-encrypt.service';


@Injectable({
  providedIn: 'root'
})
export class RequiredDocumentsService {

  constructor(private http: HttpClient, private encryptService: EncryptService) { }

  getAvailableDocumentsList(visaType, caseType, caseId, type, docType, familyId) {
    const familyIdAppUrl = familyId ?  `?familyId=${familyId}`: '';
    const encryptedType = this.encryptService.toEncrypt(type);
    return this.http.get(`/${visaType}/${caseType}/${caseId}/${encryptedType}/${docType}${familyIdAppUrl}`).pipe(
      map((response: ImagilityBaseResponse) => {
        if (response && (response.status === 200 || response.status === 204)) {
          return response;
        }
      })
    );
  }

  postSyncDocumentsList(visaType, caseType, caseId, type, docType, superTaskId, subTaskId) {
    const encryptedType = this.encryptService.toEncrypt(type);
    return this.http.post(`/${visaType}/${caseType}/${caseId}/${encryptedType}/category/${docType}/sync`, {})
      .pipe(
        map((response: ImagilityBaseResponse) => {
          if (response && (response.status === 200 || response.status === 204)) {
            return response;
          }
        })
      );
    }

  getDependentAvailableDocumentList(visaType, caseType, caseId, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/syncDocuments`, payload).pipe(map((response: ImagilityBaseResponse) => {
      if (response && (response.status === 200 || response.status === 204)) {
        return response;
      }
    })
    );
  }

  getSyncDocuments(visaType, caseType, caseId, payload) {
    return this.http.post(`/${visaType}/${caseType}/${caseId}/documentsSync`, payload).pipe(map((response: ImagilityBaseResponse) => {
      if (response && (response.status === 200 || response.status === 204)) {
        return response;
      }
    })
    );
  }

  getGCDoc(superTaskId, subTaskId , i140CaseId, fileCategory) {
    return this.http.get(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/profileDoc/category/${fileCategory}`).pipe(
        map((response: ImagilityBaseResponse) => {
            if (response && (response.status === 200 || response.status === 204)) {
                return response;
            }
        })
    );
}

syncGCDocument(superTaskId, subTaskId, i140CaseId, categoryCode) {
  return this.http.post(`/superTask/${superTaskId}/subTask/${subTaskId}/gc/i140Case/${i140CaseId}/profileDoc/category/${categoryCode}/sync`, {}).pipe(
      map((response: ImagilityBaseResponse) => {
          if (response && response.status === 200) {
              return response;
          }
      })
  );
}

}
