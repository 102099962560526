import { Component, Input, NgZone, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BillingInvoiceService } from "src/app/services/billing-invoice.service";
import { ToastrService } from "ngx-toastr";
import { ImagilityBaseResponse } from "../../../data/models/response.model";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { UploadImageDialogComponent } from "../../custom-kendo/upload-image-dialog/upload-image-dialog.component";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { TemplateService } from "src/app/services/template.service";
import * as errorUtils from 'src/app/modules/utility/global-utils';
import { CustomErrorToastComponent } from "custom-error-toast";
// import moment from "moment";
@Component({
  selector: "app-send-invoice",
  templateUrl: "./send-invoice.component.html",
  styleUrls: ["./send-invoice.component.scss"],
})
export class SendInvoiceComponent implements OnInit {
  sendInvoiceForm: FormGroup;
  @Input("invoiceObj") invoiceDetailsObj;
  @Input("obj") object;
  @Input("sendDraftItemId") sendDraftItemId;
  @Output() @ViewChild("commonkendoeditor") public commonkendoeditor: EditorComponent;
  @ViewChild("commonkendoeditorupload") public commonkendoeditorupload: UploadImageDialogComponent;
  invoiceId;
  isClicked: boolean = false;
  SendInvoiceBodyline: string;
  subjectLine: string;
  Subject: string;
  body: string;
  templateList=[];
  submitted: boolean = false;
  isEmailToError: boolean = false;
  // New Invoice from (Company Name) OR Invoice ### Due OR Invoice [Number] for [Product/Service] Due on

  constructor(
    public fb: FormBuilder,
    private invoiceService: BillingInvoiceService,
    private toaster: ToastrService,
    private ngZone: NgZone,
    private router: Router,
    private templateService: TemplateService
  ) {
    let emailregex: RegExp = /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([,.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
    this.sendInvoiceForm = this.fb.group({
      // recipientsEmailIds: ["", [Validators.required, Validators.email]],
      //  emailId: [null, [Validators.required, Validators.email]],
      templateName: [''],
      subject: ['', Validators.required],
      // bodyContent: [],
      editor: [],
      toMails: ['', {
        validators: [Validators.required, Validators.pattern(emailregex)],
        updateOn: 'blur'
      }],
    });
  }

  ngOnInit(): void {
    // console.log(history.state.invoiceId);
    // console.log("history.state.invoiceId this.object", this.object);
    // console.log(
    //   "history.state.sendDraftItemId this.sendDraftItemId",
    //   this.sendDraftItemId
    // );

    if (this.sendDraftItemId) {
      this.invoiceService
        .getInvoiceItem(this.sendDraftItemId)
        .pipe(take(1))
        .subscribe((resp) => {
          // console.log("this.sendDraftItemId", resp.data);
          this.invoiceId = resp.data.invoiceId;
          this.sendInvoiceForm.patchValue({
            subject:
              "Invoice #" +
              resp.data.invoiceNumber +
              " Due  On " +
              resp.data.invoiceDueDate,

            editor:
              "Hi" +
              "," +
              "Greetings" +
              "!" +
              "\n" +
              "It has been pleasure rendering our legal/immigration services to " +
              resp.data.billedTocompany.companyName +
              "." +
              "\n" +
              "Please find attached the invoice with charges for services rendered.",

            toMails: [resp.data.billedTocompany.email],
          });

          // billedTocompany.address.emailemailRecipients,
        });
    } else {
      if (this.invoiceDetailsObj) {
        // console.log(
        //   "invoiceObj invoiceObj invoiceDetailsObj",
        //   this.invoiceDetailsObj
        // );
        this.invoiceId = this.invoiceDetailsObj;
      }

      if (this.object) {
        // console.log("Send INVOICE ::", this.object);
        // let date = moment(this.object.invoiceDueDate.format("YYYY-MM-DD"));
        // console.log("date L::", date);
        // console.log("date L::", this.object.email);
        // console.log("Object Receiveed ::", this.object);
        // let client =

        let clientCompany = "";
        if (this.object.selectedClient) {
          clientCompany =
            this.object.selectedClient.companyName == "others"
              ? this.object.newClient
              : this.object.selectedClient.companyName;
        }
        if (this.object.selectedUploadClient) {
          clientCompany =
            this.object.selectedUploadClient.companyName == "others"
              ? this.object.newClient
              : this.object.selectedUploadClient.companyName;
        }
        const toMail = this.object.email || this.object.otherEmail;
        this.sendInvoiceForm.patchValue({
          subject:
            "Invoice #" +
            this.object.invoiceNumber +
            " Due  On" +
            this.object.invoiceDueDate,

          editor:
            "Hi" +
            "," +
            "Greetings" +
            "!" +
            "\n" +
            "It has been pleasure rendering our legal/immigration services to " +
            // if(this.object.selectedClient)
            clientCompany +
            "." +
            "\n" +
            "Please find attached the invoice with charges for services rendered.",

          toMails: [toMail],
        });
      } else {
        this.body =
          "Hi" +
          "," +
          "Greetings" +
          "!" +
          "\n" +
          "It has been pleasure rendering our legal/immigration services to you/your company." +
          "\n" +
          "Please find attached the invoice with charges for services rendered.";
        this.Subject =
          "New Invoice from (Company Name) OR Invoice ### Due OR Invoice [Number] for [Product/Service] Due on";
      }
    }
    this.getTemplateList();
  }

  commonkendoeditorimageuploaddialog() {
    this.commonkendoeditorupload.open();
  }

  onSelectEmailTemplate(event) {
    console.log(event.value);
    this.templateService.fetchEmailTemplate(event.value).subscribe((res: any) => {
      if(res){
        this.sendInvoiceForm.controls['toMails'].setValue([...res.communicationDTO.toMail]);
        // this.replyForm.controls['from'].setValue(res.communicationDTO.fromMail);
        this.sendInvoiceForm.controls['subject'].setValue(res.contentMap.header);
        this.sendInvoiceForm.controls['editor'].setValue(res.contentMap.body +'\n' + res.contentMap.footer);
      }
    });
  }

  getTemplateList(){
    const payload = {
      "superTaskTypeId": "EMALTEMPLT",
      "isDefault": "false",
      "pageNumber": 1,
      "recordsPerPage": 100,
      "searchText": ""
    }
    this.templateService.getEmailTemplateList(payload).subscribe(data => {
      console.log('data', data['documentTemplate']);
      data['documentTemplate'].forEach(element => {
        element.documentType = element.documentType.code;
        element.templateType = element.templateType.code;
        // console.log("element===>",JSON.parse(element.communicationJson));
        // console.log("element===>",element);
        this.templateList.push(element);
      });
    });
  }

   

  sendInvoice() {
    this.submitted = true;
  if (this.sendInvoiceForm.valid) {
    this.ngZone.run(() => {
      this.isClicked = true;
    });

    let emailArray = [];
    let output = [];
    output = this.sendInvoiceForm.get("toMails").value;
    emailArray = output;
    for (let i = 0; i < emailArray.length; i++) {
      emailArray[i] = emailArray[i].trim();
    }
    let payload = this.sendInvoiceForm.value;
    // console.log("SendInvoice", this.sendInvoiceForm.value);
    // let bodyData= {
    //   "contentType": "HTML",
    //   "content": this.sendInvoiceForm.get("editor").value
    // }
    let reqObj = {
      body: this.sendInvoiceForm.get("editor").value,
      subject: this.sendInvoiceForm.get("subject").value,
      // toMails: [this.sendInvoiceForm.get("toMails").value],
      // toMails: emailArray,
      toMails: emailArray,
    };
    // console.log("invoiceId", this.invoiceId);
    // console.log("this.sendInvoiceForm.value", this.sendInvoiceForm.value);
    // console.log("SendInvoice reqObj", reqObj);
    this.invoiceService.sendInvoice(this.invoiceId, reqObj).subscribe(
      (resp: ImagilityBaseResponse) => {
        // console.log("sendInvoice", resp);
        // console.log("sendInvoice :: status+", resp.status + "///");

        if (resp.status == 200) {
          this.toaster.success(resp.message, 'Success');
          this.router.navigate(["/attorney-landing/attorney-invoice"]);
        } else {
          this.toaster.warning(resp.message, 'Warning');
        }
      },
      (err) => {
        CustomErrorToastComponent.showErrorToast(this.toaster, err.message);
        console.log("sendInvoice" + "Error while sending invoice" + err);
        this.isClicked = false;
      }
    );
  }
  }

  validateEmailAddress(event, item){
    if(item=='to'){ 
      let currentData;
      if(event.value.match(`${currentData}|[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}`)){
        this.isEmailToError = false;
      } else {
        // this.template.emailTo.pop();
        this.sendInvoiceForm.controls['toMails'].value.pop();
        if(this.sendInvoiceForm.controls['toMails'].value.length>0){
          this.isEmailToError = false;
        } else {
          // this.emailclient.controls['emailids'].value = [];
          this.isEmailToError = true;
        }
      }
    }
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }

  cancelInvoice() {
    this.router.navigate(["/attorney-landing/attorney-invoice"]);
  }

  navigate() {
    if (sessionStorage.getItem('userTypeRole') === 'Attorney') {
        window.open(window.location.origin + '/attorney-landing/template/template-form', '_blank');
    }
    else if (sessionStorage.getItem('userTypeRole') === 'Petitioner'){
      window.open(window.location.origin + '/petitioner-landing/template/template-form', '_blank');
    }
  }
}
